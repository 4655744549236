import React from 'react'
import LogoMagner from "../static/magner.svg"
import LogoBallantines from "../static/logo-ballantines.svg"
import LogoRedbull from "../static/logo-redbull.svg"
import LogoSportfive from "../static/logo-sportfive.svg"
import LogoLaMartiniquaise from "../static/logo-lamartiniquaise.svg"
import LogoBullukian from "../static/logo-bullukian.svg"
import LogoSociete from "../static/logo_Société_Générale.svg"
import LogoPignol from "../static/logo-pignol.svg"
import LogoSuze from "../static/logo-suze.svg"
import LogoPernod from "../static/Pernod_Ricard_logo.svg"
import logoGroupama from "../static/groupama_stadium.svg"
import logoLagardère from "../static/Lagardère_logo.svg"
import { motion } from "framer-motion"
import { InView } from 'react-intersection-observer';
const Clients = () => {
  const animeFadeUp = {
    visible: {
      opacity: 1,
      y:0,
      transition: {
        stiffness: 1000, 
        velocity: -100,
        ease: "easeOut",
        duration: "0.6"
      }
    },
    hidden: {
      opacity: 0,
      y: '10px'
    }
  }
  return (
    <InView triggerOnce={true} threshold={0.5}>
      {({ inView, ref, entry }) => (
        <motion.div 
          ref={ref} 
          className="grid grid-cols-3 gap-x-8 gap-y-8 flex items-center mt-16 md:grid-cols-4 md:gap-x-20 lg:grid-cols-6 xl:gap-x-24 lg:gap-y-16 lg:mt-24 2xl:gap-x-32"
          initial={false}
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1,
              }
            }
          }}
          animate={inView ? "visible" : "hidden"}          
        >
          <motion.img variants={animeFadeUp} src={LogoMagner} alt="" />
          <motion.img variants={animeFadeUp} src={LogoBallantines} alt="" />
          <motion.img variants={animeFadeUp} src={LogoRedbull} alt="" />
          <motion.img variants={animeFadeUp} src={LogoSportfive} alt="" />
          <motion.img variants={animeFadeUp} src={LogoLaMartiniquaise} alt="" />
          <motion.img variants={animeFadeUp} src={LogoBullukian} alt="" />
          <motion.img variants={animeFadeUp} src={LogoSociete} alt="" />
          <motion.img variants={animeFadeUp} src={LogoPignol} alt="" />
          <motion.img variants={animeFadeUp} src={LogoSuze} alt="" />
          <motion.img variants={animeFadeUp} src={LogoPernod} alt="" />
          <motion.img variants={animeFadeUp} src={logoGroupama} alt="" />
          <motion.img variants={animeFadeUp} src={logoLagardère} alt="" />
        </motion.div>
      )}
    </InView>
  )
}

export default Clients